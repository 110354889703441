<template>
  <b-sidebar
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Company Department
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="CompanyDepartmentAddForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="companyDepartment.code"
            :rules="{ required: true }"
            name="Code"
            label="Code"
            trim />
          <TextFieldInput
            v-model="companyDepartment.name"
            :rules="{ required: true }"
            name="Name"
            label="Name"
            trim />
          <TextFieldInput
            v-model="companyDepartment.remark"
            name="Remark"
            label="Remark"
            trim />
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit">
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'

export default {
  components: { TextFieldInput },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      initialCompanyDepartment: {
        code: '',
        name: '',
        remark: ''
      },
      companyDepartment: {
        code: '',
        name: '',
        remark: ''
      }
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      this.$emit('add-company-department', this.companyDepartment)
    },
    resetForm () {
      this.companyDepartment = { ...this.initialCompanyDepartment }
      this.$refs.CompanyDepartmentAddForm.reset()
    }
  }
}
</script>

<style>

.btn-submit {
  min-width: 134px;
}
</style>
